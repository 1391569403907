import React from 'react';
import { connect } from 'react-redux';
import { selectPage } from '../actions';


class ItemMenu extends React.Component{
    
    defineColors= (typeMenu)=>{
        switch(typeMenu){
            case 'secondary':
                return {
                    color: {
                        actived: '#dc3a01',
                        common: '#e6e6e6'
                    },
                    background: '0 0'
                }
            default:
                return {
                    color: {
                        actived: '#dc3a01',
                        common: '#777'
                    },
                    background: '0 0'
                    
                }
        }
    };

    render(){
        const style = this.defineColors(this.props.typeMenu);
        
        return (
                <li className="nav-item">
                    <button onClick={() => { 
                                this.props.selectPage(this.props.actived) }}
                                style={{color: this.props.actived === this.props.selectedPage ? style.color.actived: style.color.common,
                                        background: style.background,
                                        border: 'none',
                                        padding: 0 }}>
                        { this.props.text }
                    </button>
                </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPage: state.selectedPage
    }
}

export default connect(mapStateToProps, {
    selectPage: selectPage
})(ItemMenu);
