import { combineReducers } from 'redux';


const selectedPageReducer =  (selectedPage = 'home', action) => {
    if(action.type === 'PAGE_SELECTED'){
        return action.payload;
    }

    return selectedPage;
}

const selectedLanguageReducer =  (selectedLanguage = 'pt_BR', action) => {
    if(action.type === 'LANGUAGE_SELECTED'){
        return action.payload;
    }

    return selectedLanguage;
}

const fetchedDataReducer = (data = {nada:''}, action) => {
    if(action.type === 'FETCH_DATA'){
        return action.payload;
    }
    return data;
}

const appDataReducer = (data = {}, action) => {
    if(action.type === 'SET_APP_DATA'){
        return action.payload;
    }
    return data;
}

export default combineReducers({
    selectedPage: selectedPageReducer,
    selectedLanguage: selectedLanguageReducer,
    fetchedData: fetchedDataReducer,
    appData: appDataReducer
});
