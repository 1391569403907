import jsonmacCoy from '../api/jsonmacCoy';
//import file from '../api/file.json';

export const selectPage = page => {
    return {
        type: 'PAGE_SELECTED',
        payload: page
    }
}

export const selectLanguage = lang => {
    return {
        type: 'LANGUAGE_SELECTED',
        payload: lang
    }
}

export const setAppData = (data,selectedLanguage='BR') => {
    return {
        type: 'SET_APP_DATA',
        payload: data.body[selectedLanguage]
    }
}

export const fetchData = () => async dispatch => { 
     const response = await jsonmacCoy.get(`/api`);
     dispatch({ type: 'FETCH_DATA', payload: response.data})
     //console.log(getState().users);  
 };

// export const fetchData = data => {
//     return {
//         type: 'FETCH_DATA',
//         payload: file
//     }
// }


          