import React from 'react';
import { connect } from 'react-redux';


class Slider extends React.Component{

    getSlides(slides){
        return(
            <div className="royalSlider">
                {slides.map( slide => {
                    return (
                        <div key={slide.id} className="rsContent">
                            <div className="rsABlock">
                                <img src={slide.banner} alt={slide.nome} />
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    render(){
        if(!this.props.fetchedData.header){
            return <div style={{ backgroundColor: '#000' }}>.</div>
        }

        return (
            <section className="masthead">
                {this.getSlides(this.props.fetchedData.header.slides)}
            </section>
            
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        fetchedData: state.fetchedData
    }
}

export default connect(mapStateToProps)(Slider);