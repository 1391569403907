import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Slider from './Slider';

import { selectPage } from '../actions';


class Home extends React.Component{
    getHtmlParsed(text){
        return ReactHtmlParser(text)
    }
    getEmpresas = (empresas) => {
        return(
            <ul className="clients">
                {empresas.map( empresa => {
                    if(empresa.url.length > 0){
                        return(
                            <li key={empresa.id}>
                                <a rel="noopener noreferrer" href={empresa.url} target="_blank" >
                                    <img src={empresa.logotipo} alt={empresa.nome} />
                                </a>
                            </li>
                        );
                    }

                    return(
                        <li key={empresa.id}>
                            <img src={empresa.logotipo} alt={empresa.nome} />
                        </li>
                    );
                    
                })}
            </ul>
        );
    }
    render(){
        if(!this.props.fetchedData.header || !this.props.appData.textos){
            return(
                <div id="home" style={{ backgroundColor: '#000' }}>
                    <Slider />
                </div>
            )
        }
        const t = this.props.appData.textos;
        return (
            <div id="home">
                <Slider />
                <header>
                    <div className="content">
                        <h1>{t.sou_maccoy.titulo}</h1>
                        {this.getHtmlParsed(t.sou_maccoy.texto)}
                    </div>
                </header>

                <section>
                    <div className="content home-intro" style={{background: `url(${this.props.fetchedData.header.maccoyUrl}) bottom right no-repeat`}} >
                        <h2>{t.estou_eu.titulo}</h2>
                        {this.getHtmlParsed(t.estou_eu.texto)}
                        <button className="button"
                                onClick={()=>{ this.props.selectPage(t.estou_eu.botao_destino) }}>
                                {t.estou_eu.botao_rotulo}
                        </button>
                    </div>
                </section>
                <section className="panel">
                    <div className="content">	
                        <h2>{t.grandes_nomes.titulo}</h2>
                        {this.getEmpresas(this.props.fetchedData.header.empresas)}
                    </div>
                </section>
            </div>            
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        fetchedData: state.fetchedData,
        appData: state.appData
    }
}

export default connect(mapStateToProps, {
    selectPage: selectPage
})(Home);

