import React from 'react';
import { connect } from 'react-redux';


class Testimonial extends React.Component{
    getDepoimentos = (depoimentos) => {
        return(
            <div className="testimonials">
                {depoimentos.map( depoimento => {
                    return(
                        <div key={depoimento.id} className="quote">
                            <blockquote>
                                &ldquo;{depoimento.texto}&rdquo; 
                            </blockquote>
                            <p className="quoter">
                                <strong>
                                    {depoimento.autor}
                                </strong>
                            </p>
                        </div>
                    );
                })}
            </div>
        );
    }
    render(){
        if(!this.props.appData.depoimentos){
            return <div style={{ backgroundColor: '#000' }}>.</div>
        }
        return (
            <section className="testimonial">
                <h2 className="accessibility">O que amigos e clientes dizem&hellip;</h2>
                {this.getDepoimentos(this.props.appData.depoimentos)}
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appData: state.appData
    }
}

export default connect(mapStateToProps)(Testimonial);

