import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';


class Services extends React.Component{
    getHtmlParsed(text){
        return ReactHtmlParser(text)
    }
    getServicos = (servicos) => {
        return(
            <div className="content services" >
                {servicos.map(servico => {
                    return(
                        <div key={servico.id} 
                             className="service web-development" 
                             style={{backgroundImage: `url(${servico.imagem})`, paddingBottom: '15px'}}>
                            <h2>{servico.nome}</h2>
                            {this.getHtmlParsed(servico.texto)}
                        </div>
                    );
                })}
            </div>
        );
    }
    //style={{background: `url(${this.props.fetchedData.header.maccoyUrl}) bottom right no-repeat`}}
    render(){
        if(!this.props.fetchedData.header || !this.props.appData.servicos){
            return(
                <div>.</div>
            );
        }
        const bannerUrl = this.props.fetchedData.header.bannerServicosUrl;
        const t = this.props.appData.textos;
        const servicosLinha1 = this.props.appData.servicos.filter( (value,key) => key >=0 && key <=1 );
        const servicosLinha2 = this.props.appData.servicos.filter( (value,key) => key >=2 && key <=5 );
        return (
            <div>
                <section className="masthead">
                    <div className="image">
                        <img src={bannerUrl} alt="Services" />	</div>
                    <div className="masthead-overlay"></div>
                </section>

                <header>
                    <div className="content">
                        <h1>{t.posso_fazer.titulo}</h1>
                        {this.getHtmlParsed(t.posso_fazer.texto)}
                    </div>
                </header>

                <section>
                    {this.getServicos(servicosLinha1)}
                    {this.getServicos(servicosLinha2)}
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        fetchedData: state.fetchedData,
        appData: state.appData
    }
}

export default connect(mapStateToProps)(Services);