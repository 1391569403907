import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Menu from './Menu';


class Footer extends React.Component{
    getHtmlParsed(text){
        return ReactHtmlParser(text)
    }
    render(){
        if(!this.props.appData.textos){
            return(<div style={{ backgroundColor: '#000' }}>..</div>)
        }
        const t = this.props.appData.textos;
        return (
            <footer>
                <div className="content">
                    <Menu typeMenu="secondary" />
                    <nav className="connections">
                        <h2 className="accessibility">Conecte-se comigo...</h2>
                        <ul>
                            <li className="twitter"><a rel="noopener noreferrer" href="http://www.twitter.com/MarceloMaccoy" target="_blank" >Twitter</a></li>
                            <li className="facebook"><a rel="noopener noreferrer" href="http://www.facebook.com/marcelomaccoy" target="_blank">Facebook</a></li>
                            <li className="linkedin"><a rel="noopener noreferrer" href="https://www.linkedin.com/pub/marcelo-maccoy/8/969/484" target="_blank">LinkedIn</a></li>
                        </ul>
                    </nav>
                    
                        
                    <div className="twitter-status">
                        <ul>
                            <li>
                                {this.getHtmlParsed(t.agradecimento.texto)}
                            </li>
                        </ul>
                    </div>
                    <div className="company-info">
                        <p>email: <a href="mailto:marcelo@maccoy.com.br" itemProp="email">
                                    marcelo@maccoy.com.br
                                </a> 
                            | Marcelo macCoy, <span itemProp="addressLocality">Rio de Janeiro,</span> 
                            <span itemProp="addressRegion">RJ</span>
                        </p>
                        <p>
                            <small>&copy; <span itemProp="legalName">Marcelo macCoy</span> 2019.</small></p>
                    </div>
                </div>
          </footer>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        appData: state.appData
    }
}

export default connect(mapStateToProps)(Footer);