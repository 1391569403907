import React from 'react';
import { connect } from 'react-redux';
import { selectPage, selectLanguage, setAppData } from '../actions';


class Flags extends React.Component{

    getFlags = (lflags) => {
        return(
            <div>
                {lflags.map( (flag,key) => {
                    return(
                            <i key={key} onClick={() => this.setAppLanguage(this.props.fetchedData,flag.toUpperCase())} 
                                className={flag.toLowerCase() + ' flag'}
                                style={{cursor: 'pointer'}}
                            ></i>
                        ) 
                    })
                }
            </div>
        );
    }

    setAppLanguage = async (data,language) => {
        await this.props.selectLanguage(language)
        this.props.setAppData(data,language)
    }
     
    render(){        

        if(!this.props.fetchedData.header || !this.props.appData.pages){
            return(
                <div >
                        .
                </div>
            )
        }

        const flag_list = Object.keys(this.props.fetchedData.body);
            return (
                <div className="flags">
                    {this.getFlags(flag_list)}
                </div>
                    
            );
    }
}
const mapStateToProps = (state) => {
    return {
        fetchedData: state.fetchedData,
        appData: state.appData,
        selectedLanguage: state.selectedLanguage
    }
}
export default connect(mapStateToProps, {
    selectPage: selectPage,
    selectLanguage: selectLanguage,
    setAppData: setAppData
})(Flags);