import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';


class Contact extends React.Component{
    getHtmlParsed(text){
        return ReactHtmlParser(text)
    }
    render(){
        if(!this.props.appData.textos){
            return(<div>...</div>)
        }
        const t = this.props.appData.textos;
        return (
            <div>            
                <div id="map_canvas" itemProp="map"></div>  
                <header>
                    <div className="content">
                        <h1>{t.pronto_conversar.titulo}</h1>
                        {this.getHtmlParsed(t.pronto_conversar.texto)}
                    </div>
                </header>  
                
                <section>
                    <div className="content main">
                        <div className="cols">
                            <div className="col">
                                <h2>{t.contato.titulo}</h2>                                
                                {this.getHtmlParsed(t.contato.texto)}
                                <ul className="contact-details">
                                    <li className="email"><a href="mailto:contato@maccoy.com.br" itemProp="email">contato@maccoy.com.br</a></li>
                                </ul>
                            
                                <nav className="connections">
                                    <h3>{t.redes_sociais.titulo}</h3>
                                    <ul>
                                        <li className="twitter"><a href="http://www.twitter.com/MarceloMaccoy" >Twitter</a></li>
                                        <li className="facebook"><a href="http://www.facebook.com/marcelomaccoy" >Facebook</a></li>
                                        <li className="linkedin"><a href="http://uk.linkedin.com/pub/marcelo-maccoy/8/969/484" >LinkedIn</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col">
                                <form id="macForm" action="contato.php" method="post">
                                    <fieldset>
                                        <legend>{t.envie_uma_mensagem.titulo}</legend>
                                        <p>
                                            <label htmlFor="form1_name">{t.form_nome.titulo}</label>
                                            <input id="form1_name" name="nome" className="text-input" type="text" required="required" />
                                        </p>
                                        <p>
                                            <label htmlFor="form1_company">{t.form_empresa.titulo}</label>
                                            <input id="form1_company" name="empresa" className="text-input" type="text" />
                                        </p>
                                        <p>
                                            <label htmlFor="form1_email">{t.form_email.titulo}</label>
                                            <input id="form1_email" name="email" className="text-input" type="email" required="required" />
                                        </p>
                                        <p>
                                            <label htmlFor="form1_phone">{t.form_telefone.titulo}</label>
                                            <input id="form1_phone" name="telefone" className="text-input" type="tel" />
                                        </p>
                                        <p>
                                            <label htmlFor="form1_message">{t.form_msg.titulo} </label>
                                            <textarea id="form1_message" name="mensagem" className="text-input" cols="30" rows="4" required="required"></textarea>
                                                        
                                        </p>
                                        <div className="submit-button">
                                            <button type="submit" className="button">{t.form_enviar.titulo}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        appData: state.appData
    }
}
export default connect(mapStateToProps)(Contact);