import React from 'react';
import { connect } from 'react-redux';
// compoments
import Home from './Home';
import Services from './Services';
import Contact from './Contact';
import Menu from './Menu';
import Footer from './Footer';
import Testimonial from './Testimonial';
// Reducers
import { selectPage, selectLanguage, fetchData, setAppData } from '../actions';

class App extends React.Component{

    componentDidMount = async () => {
        // all data loaded (json)
        await this.props.fetchData();
        // set default language
        await this.props.selectLanguage('BR');
        // select Page
        this.props.selectPage(this.props.fetchedData.header.home_page);
        // set AppData (by language)
        await this.props.setAppData(this.props.fetchedData,this.props.selectedLanguage);
    }

    getActivedContent(){
        // eslint-disable-next-line default-case
        switch(this.props.selectedPage){
            case 'home':
                return (
                    <div>
                        <Home />
                        <Testimonial />
                    </div>
                    )
            case 'services':
                return <Services />
            case 'contact':
                return <Contact />
            default:
                return <Home />
        }
    }

    componentDidUpdate(){
        const appHeader = this.props.fetchedData.header
        switch(this.props.selectedPage){
            case 'home':
                // reload slider and testimonials
                window.homeCalled();
                break;
            case 'contact':                
                //window.moveToTop();
                window.showGoogle(appHeader.latitude, appHeader.longitude);
                window.setMacForm();
                break;
            default:
                //window.moveToTop();
        }
    }

    render(){
        return (
            
            <div>
                <Menu />
                {this.getActivedContent()}
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fetchedData: state.fetchedData,
        selectedPage: state.selectedPage,
        appData: state.appData
    }
}

export default connect(mapStateToProps,{
    selectPage,     
    selectLanguage,
    fetchData,
    setAppData
})(App);
