import React from 'react';
import { connect } from 'react-redux';
import { selectPage } from '../actions';
import ItemMenu from './ItemMenu';
import Flags from './Flags';


class Menu extends React.Component{

    getMenuItems = (pages, typeMenu) => {
        return(
            <ul>
                {pages.map(page => {
                    return(
                            <ItemMenu typeMenu={typeMenu}
                                    text={page.label} 
                                    actived={page.page}
                                    key={page.page} 
                            />
                        ) 
                    })
                }
            </ul>
        );
    }
     
    render(){        

        if(!this.props.fetchedData.header || !this.props.appData.pages){
            return(
                <nav className="main-nav" style={{backgroundColor: '#000',borderBottom: '0px', height: '800px', paddingLeft: '30px'}}>
                    <div className="content">
                        <p>
                            <div className="prompt">.</div>
                        </p>
                    </div>
                </nav>
            )
        }

        const typeMenu = this.props.typeMenu? this.props.typeMenu: 'superior';
        const logotipoUrl = this.props.fetchedData.header.logotipoUrl;
        const pages = this.props.appData.pages;

        if(typeMenu === "superior"){
            return (
                <nav className="main-nav">
                    <div className="content">
                        <p >
                            <img onClick={() => { this.props.selectPage('home'); }} itemProp="logo" 
                                    src={logotipoUrl}
                                    alt="Marcelo macCoy" /> 
                        </p>
                        
                        {this.getMenuItems(pages,typeMenu)}
                        <Flags />
                    </div>
                    
                </nav>          
            );
        }

        return (
            
                <nav className="footer-nav">
                    <div className="content">
                        {this.getMenuItems(pages,typeMenu)}  
                    </div>
                </nav>    
        );
        
    }
}
const mapStateToProps = (state) => {
    return {
        fetchedData: state.fetchedData,
        appData: state.appData,
        selectedLanguage: state.selectedLanguage
    }
}
export default connect(mapStateToProps, {
    selectPage: selectPage
})(Menu);